<!-- DEPRECATED -->
<!-- Use OzAvatar instead -->

<template>
  <processed-image
    class="avatar"
    :src="avatarSrc"
    :transforms="transforms"
    :width="width"
    :height="height"
    :alt="avatarAltTag"
    :aria-label="avatarAltTag"
    @error="handleError"
  />
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { __ } from '@@/bits/intl'
import ProcessedImage from '@@/vuecomponents/processed_image.vue'

const ANONYMOUS_AVATAR = 'https://padlet.net/avatars/alien1.png'

export default {
  components: { ProcessedImage },
  props: {
    user: {
      type: Object,
      default: null,
    },
    avatarUrl: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 16,
    },
    height: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      hasError: false,
    }
  },
  computed: {
    avatarSrc() {
      if (this.hasError) {
        return ANONYMOUS_AVATAR
      }
      if (this.avatarUrl) {
        return this.avatarUrl
      }
      return this.user && this.user.avatar ? this.user.avatar : ANONYMOUS_AVATAR
    },
    avatarAltTag() {
      const displayName = this.user?.name || this.user?.username
      return displayName ? __('Avatar of %{name}', { name: displayName }) : __('Avatar of anonymous')
    },
    transforms() {
      if (this.width && this.height) {
        return {
          width: this.width,
          height: this.height,
          preset: 'avatar',
        }
      }
      return {
        preset: 'avatar',
      }
    },
  },
  methods: {
    handleError() {
      this.hasError = true
    },
  },
}
</script>

<style>
.avatar {
  border-radius: 96px;
}
</style>
